.uiDetailList {
    list-style-type: none;
    padding: 0px 0px 0px 0px;
    font-weight: lighter;
}

.uiDetailList a {
    color: white;
    list-style: none;
    text-decoration: none;
}