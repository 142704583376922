.item-details-div {
    display: grid;
}

.item-details-header {
    padding: 0.5rem;
    margin-bottom: 0;
    text-align: left;
    font-size: 20px;
    font-weight: bolder;
    background-color: #eeeeee;
}

.item-details-li {
    padding: 0px 0px 0px 0px;
    font-weight: lighter;
    margin: 0.5rem 0 0 2rem;
    list-style-type: square;
}

.model-options-div {
    color: #373737;
    margin: 2rem 0 0 0;
    padding: 0 0 0 0;
}

.dropdown-title {
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    border-radius: 5px;
    border: none;
    text-align: center;
    font-size: 16px;
    background-color: #fcfcfc;
    color: #373737;
    border: 1px solid #c2c2c2;
}

.dropdown-title:hover {
    border: 1px solid #373737;
}

.model-options-subheader {
    padding: 1rem 0px 0px 0.5rem;
    list-style-type: none;
    font-weight: bold;
    color: gray;
}

.model-options {
    padding: 0px 0px 0px 0px;
    font-weight: lighter;
    margin: 0.5rem 0 0 2rem;
    list-style-type: square;
}

.expand-more {
}

.item-details-p {
    padding: 0 1rem;
    font-weight: lighter;
    margin: 0.5rem 0 0 0;
    line-height: 22px;
}

.uiDetailList {
    margin: 0 0 0 0;
}

.hide {
    display: none;
}

.infinite-horizontal-scroll-div {
    overflow :hidden;
    display:inline-block;
    /* border: 1px solid red; */
    white-space: nowrap;
    padding: 8px;
    /* width: 150px; */
}

.infinite-horizontal-scroll-text-container {
    width: 10516px;
    display: flex;
    flex-wrap: nowrap;
    animation: move 80s infinite linear;
}

.infinite-horizontal-scroll-text {
    font-size: 20px;
    font-weight: bolder;
    color: #757575;
    margin: 0 1rem;
    padding-left:0px;
    /* border: 1px solid blue; */
    /* width:283px; */
}

@keyframes move {
    to {
      transform: translateX(-50%);
    }
  }