.container-lg {
    position: relative;
    overflow: hidden;
}

.container-md {
    position: relative;
    overflow: hidden;
}

.container-sm {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 260px;
    overflow: hidden;
}

.image-hero-img {
    width: 100%;
    height: auto;
}

.text_container_middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.text_container_bottom {
    position: absolute;
    bottom: 16px;
    right: 16px;
    text-align: right;
    padding: 0.2rem 1rem;
    margin: 0 0 0 16px;
    transition: all 0.2s;
}

.header {
    margin: 0 0 0 0;
}

.subheader {
    margin: 0 0 0 0;
}

.darkTxt {
    color: #373737;
}

.lightTxt {
    color: white;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(0, 0%, 71%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}