.contact-div {
    display: grid;
    max-width: 900px;
    margin: auto;
    padding: 3rem 1rem 0 1rem;
}

#contact-information-div {
    display: grid;
    row-gap: 1.5rem;
    justify-content: center;
    /* background-color: #e6e6e6; */
    padding: 2rem 0.5rem;
    margin: 0 1rem;
    border-radius: 5px;
}

.contact-information-link {
    justify-self: start;
    align-self: center;
    cursor: pointer;
    color: #373737;
    text-decoration: none;
    padding: 3px 7px;
    font-size: 16px;
}

.contact-information-link:hover {
    color: #0091ff;
}

.icon {
    margin: 0 1rem 0 0;
    font-size: 20px !important;
}