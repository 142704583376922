a {
    color: #373737;
    text-decoration: none;
}

.group-card-div {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    justify-self: stretch;
    cursor: pointer;
    border: 2px solid gainsboro;
    border-radius: 2px;
    padding: 1rem;
}

.group-card-div:hover {
    border: 2px solid #373737;
}

.group-card-div-md {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-self: stretch;
    cursor: pointer;
    border: 2px solid gainsboro;
    border-radius: 2px;
    padding: 1rem;
}

.group-card-div-md:hover {
    border: 2px solid #373737;
}

.group-card-div-sm {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-self: stretch;
    cursor: pointer;
    border: 2px solid gainsboro;
    border-radius: 2px;
    padding: 1rem;
}

.group-card-div-sm:hover {
    border: 2px solid #373737;
}

.group-card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 1rem;
}

.group-card-title {
    justify-self: center;
    align-self: center;
    padding: 5px;
    border-radius: 5px;
    font-size: 20px;
}

.group-card-item {
    display: grid;
    /* border: 1px solid gainsboro; */
    border-radius: 2px;
}

.group-card-item-img {
    justify-self: center;
    width: 100%;
    max-width: 700px;
    height: auto;
    transition: all 0.2s;
}

.group-card-item-name {
    justify-self: center;
    align-self: start;
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 0 0;
    padding: 5px 0 0 0;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(0, 0%, 71%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}