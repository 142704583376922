.intro_container {
    display: grid;
    margin: 7vh 0 3vh 0;
    text-align: center;
}

.intro-div-title {
    margin: 0px 0px 0px 0px;
    justify-self: center;
    letter-spacing: 2px;
    font-weight: bold;
}

.intro-div-subtitle {
    color: gray;
    margin: 0px 0px 0px 0px;
    justify-self: center;
}