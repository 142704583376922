.document-card-div {
    display: grid;
    cursor: pointer;
}

.document-card-image {
    align-self: start;
    width: 100%;
    height: auto;
}

.document-card-image:hover {
    transform: scale(1.05);
}

.document-card-title {
    align-self: start;
    justify-self: center;
    text-align: center;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(0, 0%, 71%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}