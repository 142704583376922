a {
    text-decoration: none;
}

.product-item {
    justify-self: stretch;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: 
    "image name name"
    "image details description";
    cursor: pointer;
    column-gap: 1.5rem;
    padding: 0 1rem;
    border: 2px solid gainsboro;
    border-radius: 2px;
}

.product-item:hover {
    border: 2px solid #373737;
}

.product-item-md {
    justify-self: stretch;
    display: grid;
    grid-template-columns: 3fr 4fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
    "image name"
    "image details";
    cursor: pointer;
    column-gap: 1rem;
    padding: 0 1rem;
    border: 2px solid gainsboro;
    border-radius: 2px;
}

.product-item-md:hover {
    border: 2px solid #373737;
}

.product-item-sm {
    justify-self: stretch;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: auto;
    grid-template-areas: 
    "image image"
    "name name"
    "details details";
    cursor: pointer;
    column-gap: 1rem;
    padding: 0 1rem;
    border: 2px solid gainsboro;
    border-radius: 2px;
}

.product-item-sm:hover {
    border: 2px solid #373737;
}

.product-item-img-div {
    display: grid;
    grid-area: image;
    overflow: hidden;
    line-height: 0px;
}

.product-card-image {
    align-self: center;
    justify-self: center;
    width: 100%;
    height: auto;
    transition: all 0.2s;
}

.product-card-name {
    grid-area: name;
    align-self: start;
    justify-self: start;
    color: #373737;
    font-size: 20px;
    font-weight: bold;
}

.product-card-details {
    grid-area: details;
    align-self: start;
    justify-self: start;
    font-size: 14px;
    padding: 5px 5px 1rem 5px;
    color: #373737;
    max-width: 330px;
}

.product-card-details-li {
    padding: 0px 0px 0px 0px;
    font-weight: lighter;
    margin: 5px 0 0 5px;
    list-style-type: square;
}

.product-card-details-more {
    padding: 0px 0px 0px 0px;
    font-weight: bolder;
    margin: 5px 0 0 5px;
    list-style-type: square;
}

.product-card-description {
    grid-area: description;
    align-self: start;
    justify-self: start;
    font-weight: lighter;
    padding: 5px 5px 5px 5px;
    line-height: 22px;
    font-size: 14px;
    border: 1px solid gainsboro;
    border-radius: 2px;
    color: #373737;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(0, 0%, 71%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}