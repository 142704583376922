.footer_container {
    display: grid;
    /* background: rgb(249, 249, 249); */
    /* height: 200px; */
    width: 100%;
    margin: 8rem 0 0 0;
    text-align: center;
}

.footer_container_sm {
    display: grid;
    background: #1B2226;
    height: 200px;
    width: 100%;
    margin: 5rem 0 0 0;
    padding: 0 0 0 58px;
    text-align: center;
}

.footer_container a {
    list-style: none;
    color: rgb(74, 74, 74);
    text-decoration: none;
}

.footer_container_sm a {
    color: white;
    list-style: none;
    text-decoration: none;
}

.footer-link {
    font-weight: normal;
}

.footer-link:hover {
    color: red;
}

.copyright_footer {
    font-weight: bold;
    padding: 2rem 0;
}