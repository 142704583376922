.item-images-div {
    grid-area: images;
    align-self: start;
    display: grid;
    margin: 1rem 0 0 0;
}

/* lightbox main div */
.ReactModal__Body--open {
    height: 100vh;
    overflow-y: hidden;
}

.item-main-img-container {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 0 0 0 0;
    text-align: center;
}

.item-main-img-container svg {
    pointer-events: none;
}

.item-main-img {
    width: 100%;
    min-height: 200px;
    height: 500px;
    max-height: 35vh;
    object-fit: cover;
    cursor: pointer;
}

.item-main-img h3 {
    padding: 0.5rem;
    margin-bottom: 0;
    color: white;
    text-align: left;
    font-weight: normal;
}

.item-main-img img {
    width: 100%;
    height: auto;
    cursor: pointer;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(0, 0%, 71%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.item-img-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 1rem;
    margin: 1rem 0 1rem 0;
    padding: 0 0 0 0;
    overflow: hidden;
}

.item-img-grid-single {
    width: 100%;
    height: auto;
    align-self: stretch;
    object-fit: cover;
    cursor: pointer;
}

#item-video-container {
    width: 100%;
    height: auto;
    align-self: stretch;
    position: relative;
    display: inline-block;
}

#item-video-container img {
    filter: brightness(0.8);
    object-fit: cover;
    width: 100%;
    height: 100%;
}

#item-video-container svg {
    pointer-events: none;
}

#video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    z-index: 1;
    cursor: pointer;
}

#video-icon-lg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    z-index: 1;
    cursor: pointer;
}

.seeMoreImg {
    justify-self: center;
    cursor: pointer;
    background-color: #fcfcfc;
    color: #373737;
    border: 1px solid #c2c2c2;
    margin: 0 0 1rem 0;
    padding: 0.5rem 1rem;
    font-size: 16px;
    text-decoration: none;
    max-width: 300px;
    border-radius: 0.2rem;
}

.seeMoreImg:hover {
    border: 1px solid #373737;
}

.item-main-img p {
    text-align: left;
    padding: 0 0.5rem;
}

.lb-button {
    background-color: darkred;
}

.lb-button:hover, .prev:hover, .next:hover {
    background-color: black !important;
}

.prev, .next {
    background-color: darkred !important;
}