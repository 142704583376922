.backButton {
    background:  #485a69;
    color: white;
    border: none;
    margin: 1rem 1rem 0 1rem;
    padding: 10px 12px;
    cursor: pointer;
    border-radius: 3px;
    font-family: 'Roboto', sans-serif;
}

.backButton:hover {
    background-color: #008bfd;
}