.suggested-items-container {
    justify-self: center;
    max-width: 1200px;
    margin: 7rem 0 0 0;
}

.suggested-items-header {
    text-align: center;
    justify-self: center;
    font-size: 20px;
    font-weight: bold;
}