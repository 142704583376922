.contact-bar-div {
    grid-area: contactBar;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    /* background-color: #ebebeb; */
    padding: 1rem;
}

.contact-bar-button {
    justify-self: stretch;
    align-self: start;
    background-color: #fcfcfc;
    color: #373737;
    border: 1px solid #c2c2c2;
    text-decoration: none;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.contact-bar-button:hover {
    border: 1px solid #373737;
}