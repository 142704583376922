.contact_form {
    display: grid;
    grid-area: itemContactForm;
    align-self: start;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 0.5rem;
    background: #EEEEEE;
    padding: 2rem 2rem;
    margin: 1rem 1rem 7vh 1rem;
    border-radius: 15px;
    justify-items: center;
    grid-template-areas: 
    "firstNameLabel lastNameLabel"
    "formFirstName formLastName"
    "phoneNumberLabel emailLabel"
    "formPhoneNumber formEmail"
    "customerTypeLabel customerTypeLabel"
    "formCustomerType formCustomerType"
    "companySchoolNameLabel accountNumberLabel"
    "formCompanySchoolName formAccountNumber"
    "messageLabel messageLabel"
    "formMessage formMessage"
    "successMessage successMessage"
    "failureMessage failureMessage"
    "formSubmit formSubmit";
}

.contact_form_school {
    display: grid;
    grid-area: itemContactForm;
    align-self: start;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 0.5rem;
    background: #EEEEEE;
    padding: 2rem 2rem;
    margin: 1rem 1rem 7vh 1rem;
    border-radius: 15px;
    justify-items: center;
    grid-template-areas: 
    "firstNameLabel lastNameLabel"
    "formFirstName formLastName"
    "phoneNumberLabel emailLabel"
    "formPhoneNumber formEmail"
    "customerTypeLabel companySchoolNameLabel"
    "formCustomerType formCompanySchoolName"
    "messageLabel messageLabel"
    "formMessage formMessage"
    "successMessage successMessage"
    "failureMessage failureMessage"
    "formSubmit formSubmit";
}

small {
    color: coral;
}

.contact_form input, .contact_form_school input {
    font-size: large;
    text-align: center;
    /* max-width: 700px; */
    border-radius: 0.2rem;
    border: none;
    padding: 0.3rem 0;
    margin: 0 0 1rem 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    -webkit-appearance: none;
    box-shadow: 5px 5px 10px #cfcfcf;
}

.contact_form input:focus, .contact_form_school input:focus {
    outline: 3px #0091ff solid;
}

.contact_form select, .contact_form_school select {
    font-size: large;
    /* background-color: white; */
    border: 2px #373737 solid;
    border-radius: 5px;
    padding: 0.3rem 0.5rem;
    margin: 0 0 1rem 0;
}

.contact_form textarea, .contact_form_school textarea {
    font-size: larger;
    text-align: center;
    /* max-width: 700px; */
    border-radius: 0.2rem;
    border: none;
    padding: 0.3rem 0;
    margin: 0 0 1rem 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    -webkit-appearance: none;
    box-shadow: 5px 5px 10px #cfcfcf;
}

.contact_form textarea:focus, .contact_form_school textarea:focus {
    outline: 3px #0091ff solid;
}

.form_email_icon {
    grid-area: formEmailIcon;
    margin: 1rem;
}

.form_first_name {
    grid-area: formFirstName;
    width: 100%;
}

.first_name_label {
    grid-area: firstNameLabel;
}

.form_last_name {
    grid-area: formLastName;
    width: 100%;
}

.last_name_label {
    grid-area: lastNameLabel;
}

.form_phone_number {
    grid-area: formPhoneNumber;
    width: 100%;
}

.phone_number_label {
    grid-area: phoneNumberLabel;
}

.form_email {
    grid-area: formEmail;
    width: 100%;
}

.email_label {
    grid-area: emailLabel;
}

.form_customer_type {
    grid-area: formCustomerType;
    /* align-self: start; */
    width: 100%;
}

.customer_type_label {
    grid-area: customerTypeLabel;
}

.form_account_number {
    grid-area: formAccountNumber;
    width: 100%;
}

.account_number_label {
    grid-area: accountNumberLabel;
}

.form_company_school_name {
    grid-area: formCompanySchoolName;
    width: 100%;
}

.company_school_name_label {
    grid-area: companySchoolNameLabel;
}

.form_message {
    grid-area: formMessage;
    width: 100%;
}

.message_label {
    grid-area: messageLabel;
}

.form_submit_btn {
    grid-area: formSubmit;
    justify-self: center;
    cursor: pointer;
    background: #373737;
    color: white;
    border: none;
    padding: 0.3rem 7px;
    width: 100%;
    height: 100%;
    font-size: large;
    /* max-width: 300px; */
    border-radius: 0.2rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.form_submit_btn:hover {
    background-color: #0091ff;
}

.form_submit_btn:focus {
    outline: 3px #0091ff solid;
}

.form-submit-success-message {
    grid-area: successMessage;
    align-self: start;
    background-color: #bffeb3;
    justify-self: center;
    padding: 0.1rem 1rem;
    border-radius: 5px;
    margin: 0 0 1rem 0;
}

.form-submit-failure-message {
    grid-area: failureMessage;
    align-self: start;
    background-color: #feb3b3;
    justify-self: center;
    padding: 0.1rem 1rem;
    border-radius: 5px;
    margin: 0 0 1rem 0;
}