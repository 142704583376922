.catalog_item {
    justify-self: center;
    align-self: center;
    cursor: pointer;
    border-bottom: 4px solid rgba(255, 255, 255, 0);
}

.catalog_item:hover {
    border-bottom: 4px solid red;
}

.catalog_image {
    width: 100%;
    height: auto;
    transition: 0.5s ease-out;
}

.catalog_image:hover {
    transform: scale(1.05);
}

.catalog_item a {
    list-style: none;
    text-decoration: none;
}

#catalog-name {
    text-align: center;
    font-weight: bolder;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(0, 0%, 71%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}