.home-div {
    display: grid;
    justify-content: center;
    max-width: 1400px;
    margin: auto;
}

.page-intro-container {
    display: grid;
    position: relative;
    margin: 0 0 0 0;
    cursor: pointer;
    animation: fadeIn 0.25s;
    -webkit-animation: fadeIn 0.25s;
    -moz-animation: fadeIn 0.25s;
    -o-animation: fadeIn 0.25s;
    -ms-animation: fadeIn 0.25s;
}

.page-intro-img {
    width: 100%;
    height: auto;
}

.page-intro-content-lg {
    display: grid;
    justify-items: center;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeIn 2s;
    overflow: visible;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
}

.page-intro-content-md {
    display: grid;
    justify-items: center;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
}

.page-intro-content-sm {
    display: grid;
    justify-items: center;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
}

.page-intro-txt-lg {
    color: white;
    font-size: 120px;
    margin: 1rem 0 1rem 0;
}

.page-intro-txt-md {
    color: white;
    font-size: 80px;
    margin: 1rem 0 1rem 0;
}

.page-intro-txt-sm {
    color: white;
    font-size: 60px;
    margin: 1rem 0 1rem 0;
}

.page-intro-btn-lg {
    cursor: pointer;
    background-color: #373737;
    color: white;
    border: none;
    padding: 4px 16px;
    font-size: 18px;
    text-decoration: none;
    max-width: 300px;
    border-radius: 0.2rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.page-intro-btn-md {
    cursor: pointer;
    background-color: #373737;
    color: white;
    border: none;
    padding: 4px 16px;
    font-size: 15px;
    text-decoration: none;
    max-width: 300px;
    border-radius: 0.2rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.page-intro-btn-sm {
    cursor: pointer;
    background-color: #373737;
    color: white;
    border: none;
    padding: 4px 16px;
    font-size: 14px;
    text-decoration: none;
    max-width: 300px;
    border-radius: 0.2rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.page-intro-btn-lg:hover, .page-intro-btn-md:hover, .page-intro-btn-sm:hover {
    background-color: #ba1313;
}

#image_hero_group_lg {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    max-width: 1800px;
    margin: 1rem 0 0 0;
    padding: 0 1rem;
    animation: fadeIn 0.25s;
    -webkit-animation: fadeIn 0.25s;
    -moz-animation: fadeIn 0.25s;
    -o-animation: fadeIn 0.25s;
    -ms-animation: fadeIn 0.25s;
}

#image_hero_group_lg h1 {
    font-size: 36px;
}

#image_hero_group_md {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    max-width: 1800px;
    margin: 1rem 0 0 0;
    animation: fadeIn 0.25s;
    -webkit-animation: fadeIn 0.25s;
    -moz-animation: fadeIn 0.25s;
    -o-animation: fadeIn 0.25s;
    -ms-animation: fadeIn 0.25s;
}

#image_hero_group_md h1 {
    font-size: 24px;
}

#image_hero_group_sm {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    max-width: 1800px;
    margin: 1rem 0 0 0;
    animation: fadeIn 0.25s;
    -webkit-animation: fadeIn 0.25s;
    -moz-animation: fadeIn 0.25s;
    -o-animation: fadeIn 0.25s;
    -ms-animation: fadeIn 0.25s;
}

#image_hero_group_sm h1 {
    font-size: 24px;
}

.featured-products-home {
    margin: 0 0 3rem 0;
    animation: fadeIn 0.25s;
    -webkit-animation: fadeIn 0.25s;
    -moz-animation: fadeIn 0.25s;
    -o-animation: fadeIn 0.25s;
    -ms-animation: fadeIn 0.25s;
}

#home-categories-container {
    margin: 0 0 4rem 0;
}

.home-alert {
    text-align: center;
    padding: 20vh 0;
    font-weight: lighter;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(0, 0%, 71%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}