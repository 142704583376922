.catalogs-div {
    padding: 3rem 0 0 0;
}

.catalog_grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    padding: 0 1rem;
    max-width: 1400px;
    margin: auto;
}