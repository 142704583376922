.category-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: auto;
    list-style-type: none;
    padding: 0px 1rem 0px 1rem;
    text-align: center;
}

.category-grid a {
    color: #373737;
    list-style: none;
    text-decoration: none;
}

.category-grid li {
    background: #eeeeee;
    border-radius: 5px;
    animation-duration: 0.3s;
    animation-name: slideLeft;
    padding: 0.2rem 0 0.2rem 0;
}

.category-grid li:hover {
    background: #003965;
    color: white;
}

.category-image {
    width: 80%;
    border-radius: 5px;
}

.subcategory-image {
    width: 80%;
    border-radius: 5px;
}

@keyframes slideLeft {
    from {
        margin-left: 100%;
        width: 500%;
    }
    to {
        margin-left: 0%;
        width: 100%;
    }
}