.search-div {
    display: grid;
    margin: 3rem 0 0 0;
}

.search-form {
    display: grid;
    grid-template-rows: auto;
    max-width: 1400px;
    justify-self: center;
    justify-items: center;
}

.search-input {
    border: none;
    padding: 0.3rem 0.5rem;
    font-size: large;
    text-align: center;
    border-radius: 5px;
    -webkit-appearance: none;
    box-shadow: 5px 5px 10px #cfcfcf;
}

.search-input:focus {
    outline: none;
}

.search-btn {
    cursor: pointer;
    background: #373737;
    color: white;
    border: none;
    padding: 3px 7px;
    font-size: large;
    border-radius: 0.2rem;
    margin: 1rem;
}

.search-btn:hover {
    background-color: #0091ff;
}

.search-suggestions-div {
    display: grid;
    margin: 1rem 1rem 2rem 1rem;
    row-gap: 6px;
    justify-self: center;
}

.suggestion-link {
    text-decoration: none;
    cursor: pointer;
    color: #373737;
    font-size: 16px;
    padding: 3px 1rem;
    background-color: #e7e7e7;
    border-radius: 5px;
}

.suggestion-link:hover {
    color: #0091ff;
}

.suggestion-search-icon {
    margin: 0 1rem 0 0;
}