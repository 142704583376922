.social_media_bar_con {
    display: grid;
    justify-content: center;
    background: #D1D1D1;
    line-height: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
    padding: 0 40%;
}

.social_media_bar_con a {
    /* color: white; */
    list-style: none;
    text-decoration: none;
}

.social_media_bar_con a:hover {
    color: red;
}