.appbar {
    background-color: #fcfcfc !important;
    box-shadow: none !important;
    z-index: 98 !important;
}

.topnav-div a {
    color: #373737;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
}

.topnav-products-menu {
    margin: 35px 0 0 0;
}

.topnav-products-menu ul {
    background-color: #eeeeee !important;
    padding: 0 0 0 0;
}

.topnav-products-menu li:hover {
    background-color: #d0d0d0 !important;
}

.topnav-products-menu a {
    color: #373737;
    text-decoration: none;
    letter-spacing: 2px !important;
}

.topnav-products-menu-item {
    letter-spacing: 2px !important;
}

.topnav-products-menu-mobile {
    margin: 35px 0 0 0;
}

.topnav-products-menu-mobile ul {
    background-color: #eeeeee !important;
}

.topnav-products-menu-mobile li:hover {
    background-color: #d0d0d0 !important;
}

.topnav-products-menu-mobile a {
    text-decoration: none;
}

.topnav-products-menu-mobile p {
    color: #373737;
    font-weight: bold;
    letter-spacing: 2px;
}

.topnav-typography {
    line-height: normal;
    padding: 0.5rem 1rem;
    margin: 0 1rem;
    cursor: pointer;
    border-bottom: 4px #fcfcfc solid;
    letter-spacing: 2px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.topnav-typography:hover {
    border-bottom: 4px red solid;
}

.topnav-icon {
    /* background: gray; */
    padding: 4px 1rem 0 1rem ;
}

.topnav-icon:hover {
    color: red;
}