.document-grid-div-lg {
    display: grid;
    margin: auto;
    justify-content: center;
    padding: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(180px, 225px));
    grid-template-rows: auto;
    max-width: 1400px;
    gap: 1rem;
}

.document-grid-div-md {
    display: grid;
    margin: auto;
    justify-content: center;
    padding: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    max-width: 1400px;
    gap: 1rem;
}

.document-grid-div-sm {
    display: grid;
    margin: auto;
    justify-content: center;
    padding: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(180px, 225px));
    grid-template-rows: auto;
    max-width: 1400px;
    gap: 1rem;
}