#modal-video-container {
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.816);
    border: none;
    overflow: visible;
}

/* add media query to change max height when screen height smaller than ratio*/
#video-iframe {
    justify-self: center;
    align-self: center;
    width: 100%;
    max-width: 1200px;
    height: calc(100vw * 0.56);
    max-height: calc(1200px * 0.56);
    border: none;
}

#modal-video-back-btn {
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
    border: none;
}

@media (max-height: 700px) {
    #video-iframe {
        justify-self: center;
        align-self: center;
        width: 100%;
        max-width: 500px;
        height: calc(100vw * 0.56);
        max-height: 250px;
        border: none;
    }
}