.item-container {
    display: grid;
    justify-content: center;
}

.item-large-div {
    display: grid;
    grid-template-columns: 59% 39%;
    /* grid-template-rows: auto auto 1fr; */
    grid-template-rows: auto auto auto auto 1fr;
    grid-auto-flow: dense;
    margin: auto;
    padding: 4rem 2rem 0 2rem;
    max-width: 1200px;
    column-gap: 2%;
    grid-template-areas:
    "itemActions details"
    "itemActions details"
    "itemActions details"
    "itemActions details"
    "itemActions details";
}

.item-medium-div {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin: auto;
    padding: 2rem 2rem 0 2rem;
    max-width: 1000px;
    grid-template-areas: 
    'itemActions'
    'details';
}

.item-small-div {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin: auto;
    padding: 2rem 1rem 0 1rem;
    max-width: 1000px;
    grid-template-areas:
    'itemActions'
    'details';
}

.item-name {
    grid-area: name;
    justify-self: left;
    align-self: start;
    padding: 0 1rem;
    margin: 1rem 0 0 0;
    font-size: 24px;
}

/* .item-sku {
    grid-area: sku;
    color: gray;
    justify-self: left;
    align-self: start;
    padding: 0 1rem;
    margin: 5px 0 0 0;
    font-size: 16px;
} */

.item-details-div {
    grid-area: details;
    align-self: start;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    padding-top: 2.5rem;
}

#item-actions-lg {
    grid-area: itemActions;
    align-self: start;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
}

#item-actions-md {
    grid-area: itemActions;
    align-self: start;
}

#item-actions-sm {
    grid-area: itemActions;
    align-self: start;
}