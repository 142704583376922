  .marquee-container {
    display: grid;
    justify-self: center;
    justify-content: center;
    justify-items: center;
    gap: 1.5rem;
    margin: 4rem 0 0 0;
    padding: 1rem 0;
    background-color: #eeeeee;
    overflow: hidden;
    width: 100%;
    max-width: 1200px;
  }
  
  .marquee {
    height: 70px;
    /* width should equal: (orb width * (number of orbs)) + (orb side margin * (number of orbs * 2)) */
    position: relative;
  }

  .marquee-title {
    font-size: 20px;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }
  
  .marquee-inner {
    display: block;
    width: 200%;
    margin: 0 0 0 0;
    position: absolute;
    animation: marquee 30s linear infinite;
  }
  
  /* .marquee-inner:hover {
    animation-play-state: paused;
  } */
  
  .span {
    float: left;
    width: 50%;
  }

  .orb {
    width: 150px;
    height: 70px;
    background: #ffffff;
    display: grid;
    margin: 0 25px;
    border-radius: 10px;
    overflow: hidden;
    float: left;
    justify-content: center;
    align-content: center;
    transition: all .2s ease-out;
    /* filter: drop-shadow(5px 5px 10px #DADADA); this was not supported on Safari */
    box-shadow: 5px 5px 10px #DADADA;
  }
  
  .orb:hover {
    cursor: default;
  }

  .marquee-image {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    width: 100%;
  }
  
  @keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }
  }