.prodGrid {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    margin-top: 1rem;
    max-width: 1400px;
    list-style-type: none;
    padding: 0px 1rem 0px 1rem;
}

.prodGrid a {
    color: white;
    list-style: none;
    text-decoration: none;
}

.prodGrid-md {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    margin-top: 1rem;
    max-width: 1400px;
    list-style-type: none;
    padding: 0px 0.5rem;
}

.prodGrid-md a {
    color: white;
    list-style: none;
    text-decoration: none;
}

.prodGrid-sm {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    margin-top: 1rem;
    list-style-type: none;
    padding: 0px 0.25rem;
}

.prodGrid-sm a {
    color: white;
    list-style: none;
    text-decoration: none;
}

.no-result-message {
    text-align: center;
    margin: auto;
    background-color: rgb(255, 223, 220);
    border-radius: 5px;
    padding: 3px 7px;
    justify-self: center;
    max-width: 500px;
}