.productGridItem {
    justify-self: stretch;
}

.product-grid-item-img-div {
    overflow: hidden;
    margin: 0 0 5px 0;
    line-height: 0px;
}

.product-grid-card-image {
    width: 100%;
    max-width: 700px;
    height: auto;
    transition: all 0.2s;
}

.product-grid-card-image:hover {
    transform: scale(1.1);
}

.product-grid-card-name {
    margin: 0 0.5rem 0 0.5rem;
    color: #373737;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0.5rem 1rem 0.5rem;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(0, 0%, 71%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}